import React, { Component } from 'react';
import './css/BSF.css';

class BSF extends Component {

    render() {
        return (
            <div id="BSF-container">
                <div id="BSF-title" className="title">What is Black Soldier Fly (BSF)? </div>
                <div className="BSF-row">
                    <img id="BSF-main" src={this.props.path + "/Images/BSF/BSF.png"}></img>
                    <div className="BSF-column">
                        <div className = "button" onClick={()=>this.props.switchPage("Lifecycle", this.props.view)}>Life Cycle</div>
                        <div className = "button" onClick={()=>this.props.switchPage("Environment", this.props.view)}>BSF & Environment</div>
                        <div className = "button" onClick={()=>this.props.switchPage("Advantages", this.props.view)}>Advantages</div>
                        <div className = "button" onClick={()=>this.props.switchPage("RaiseBSF", this.props.view)}>How to raise BSF?</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BSF; 