import React, {Component} from 'react';
import './css/ProductPage.css';

class Product_page extends Component{    

    render_function(data){
        var output = data.map((datum, key) =>{
            return (
                <div key={key} className = "case-container">
                    <span className = "case-title">{datum.content}</span>
                    <img 
                        id = {datum.src}
                        className = "case-image"
                        src={this.props.path + "/Images/ProductPage/" + datum.product_name + '/' + datum.src}
                        alt={datum.src}
                    />
                </div>
            )
        })
        return output; 
    }

    render(){
        var path = this.props.path; 
        var data = this.props.data; 
        var product = data.description.product_name; 
        
        return(
            <div className = "productPage-container"> 
                <div id="productPage-title" className ="title">Product</div>
                <span className="productPage-name subtitle">{data.title.content}</span>
                <div className="productPage-info">
                    <div className= "flex column">
                        <img 
                            src = {path + "/Images/products/" + data.description.src}
                            alt = {product}
                            className = "productPage-product-image"
                        />
                        <span className="productPage-technology" onClick={() => this.props.switchPage(`${product}Technology`, this.props.view) }>Technology</span>
                    </div>
                    <p className = "productPage-description">
                        {data.description.content}
                    </p>
                </div>
                <span className="productPage-subtitle subtitle">Processing case</span>
                {this.render_function(data.case)}
                <img 
                    className = "product-spec"
                    src={path + "/Images/ProductPage/" + data.description.product_name + "/spec.png"}
                    alt="spec"
                />
                <span 
                    onClick = {() => this.props.fetchPage("Contact", this.props.view)}
                    className = "contact-btn"
                > Contact Us </span>
            </div>
        )
    }
}

export default Product_page; 