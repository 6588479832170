// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RaiseBSF-row {
    display: flex;
    margin-top: 20px; 
    align-items: start;
    justify-content: space-evenly;
    width: 95vw; 
}

.RaiseBSF-item {
    display: flex;
    width: auto; 
    min-width: 600px;
    justify-content: space-evenly;
    background-color: lightgreen;
    align-items: center;
    color: green;
    padding: 20px;
    border-radius: 25px;
}

#RaiseBSF-container {
    margin: 20px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.RaiseBSF-img{
    width: auto; 
    height: 100px; 
    margin: 20px; 
}

@media (min-width: 1000px){
    .RaiseBSF-item {
        width: 60vw;
    }

    .RaiseBSF-img{
        aspect-ratio: 16/9;
        width: auto; 
        height: 10vh; 
        align-self: center;
        margin: 20px; 
    }
}
`, "",{"version":3,"sources":["webpack://./src/View/css/RaiseBSF.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,6BAA6B;IAC7B,4BAA4B;IAC5B,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,kBAAkB;QAClB,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,YAAY;IAChB;AACJ","sourcesContent":[".RaiseBSF-row {\n    display: flex;\n    margin-top: 20px; \n    align-items: start;\n    justify-content: space-evenly;\n    width: 95vw; \n}\n\n.RaiseBSF-item {\n    display: flex;\n    width: auto; \n    min-width: 600px;\n    justify-content: space-evenly;\n    background-color: lightgreen;\n    align-items: center;\n    color: green;\n    padding: 20px;\n    border-radius: 25px;\n}\n\n#RaiseBSF-container {\n    margin: 20px;\n    width: auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-evenly;\n}\n\n.RaiseBSF-img{\n    width: auto; \n    height: 100px; \n    margin: 20px; \n}\n\n@media (min-width: 1000px){\n    .RaiseBSF-item {\n        width: 60vw;\n    }\n\n    .RaiseBSF-img{\n        aspect-ratio: 16/9;\n        width: auto; \n        height: 10vh; \n        align-self: center;\n        margin: 20px; \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
