import React, { Component } from 'react';
import Home from "./View/Home.js";
import ViewModel from "./ViewModel.js";
import NotFound from "./View/NotFound.js";
import TopNavigationBar from "./View/TopNavigationBar.js";
import Contact from "./View/Contact.js";
import Education from "./View/Education.js";
import BSF from "./View/BSF.js";
import Workshop from "./View/Workshop.js";
import Menu from "./View/Menu.js";
import ProductPage from "./View/ProductPage.js";
import ProductMenu from "./View/ProductMenu.js";
import About from "./View/About.js";
import GuidedTour from "./View/GuidedTour.js";
import ZerosonicTechnology from "./View/Technology/Zerosonic.js";
import SWPTechnology from "./View/Technology/SWP-120.js";
import AlphaGaiaTechnology from "./View/Technology/Alpha_Gaia.js";
import Competition from "./View/Competition.js";
import Kindergarten from "./View/Competition/Kindergarten.js";
import Secondary from "./View/Competition/Secondary.js";
import Primary from "./View/Competition/Primary.js";
import OnlineSubmission from "./View/OnlineSubmission.js";
import Footer from "./View/Footer.js";
import Lifecycle from "./View/BSF/Lifecycle.js";
import Environment from "./View/BSF/Environment.js";
import Advantages from "./View/BSF/Advantages.js";
import RaiseBSF from "./View/BSF/RaiseBSF.js";
import News from "./View/News.js";
import News1 from "./View/News1.js"

const local_server = false;
const path = local_server ? "http://192.168.3.10:45221" : "https://ecoenergy-tech.com/db";
const components = {
	"Home": Home,
	"Contact": Contact,
	"Education": Education,
	"BSF": BSF,
	"Workshop": Workshop,
	"Products": ProductPage,
	"ProductMenu": ProductMenu,
	"About": About,
	"GuidedTour": GuidedTour,
	"ZerosonicTechnology": ZerosonicTechnology,
	"SWPTechnology": SWPTechnology,
	"Alpha_GaiaTechnology": AlphaGaiaTechnology,
	"Competition": Competition,
	"Kindergarten": Kindergarten,
	"Secondary": Secondary,
	"Primary": Primary,
	"OnlineSubmission": OnlineSubmission,
	"Lifecycle": Lifecycle,
	"Environment": Environment,
	"Advantages": Advantages,
	"RaiseBSF": RaiseBSF,
	"News": News, 
	"News1": News1
}

const back_components = [
	"BSF",
	"Workshop",
	"Products",
	"GuidedTour",
	"ZerosonicTechnology",
	"SWPTechnology",
	"Alpha_GaiaTechnology",
	"Competition",
	"Kindergarten",
	"Secondary",
	"Primary",
	"Lifecycle",
	"Environment",
	"Advantages",
	"RaiseBSF",
]

class View extends Component {

	conditionalRendering() {
		var component_name = ViewModel.state.current_page
		if (components.hasOwnProperty(component_name)) {
			var Component = components[ViewModel.state.current_page];
			return (
				<Component
					view={this}
					data={ViewModel.state.data}
					command={ViewModel.command}
					fetchPage={ViewModel.fetchPage}
					switchPage={ViewModel.switchPage}
					fileCommand={ViewModel.fileCommand}
					path={path}
				/>
			)
		}
		else {
			return <NotFound />
		}
	}

	async componentDidMount() {
		await ViewModel.fetchPage(null, this);
	}

	async back() {
		if (ViewModel.state.current_page == "BSF" || ViewModel.state.current_page == "Workshop" || ViewModel.state.current_page == "GuidedTour" || ViewModel.state.current_page == "Competition") {
			await ViewModel.fetchPage("Education", this);
			return;
		}
		else if (ViewModel.state.current_page == "Kindergarten" || ViewModel.state.current_page == "Secondary" || ViewModel.state.current_page == "Primary") {
			await ViewModel.fetchPage("Competition", this);
			return;
		}
		else if (ViewModel.state.current_page == "Products") {
			await ViewModel.fetchPage("ProductMenu", this);
			return;
		}
		else if (ViewModel.state.current_page == "Alpha_GaiaTechnology") {
			await ViewModel.command("Products", "GET", "product=Alpha_Gaia", this)
			return;
		}
		else if (ViewModel.state.current_page == "ZerosonicTechnology") {
			await ViewModel.command("Products", "GET", "product=Zerosonic", this)
			return;
		}
		else if (ViewModel.state.current_page == "SWPTechnology") {
			await ViewModel.command("Products", "GET", "product=SWP", this)
			return;
		}
		else if (ViewModel.state.current_page == "Lifecycle" || ViewModel.state.current_page == "Environment" || ViewModel.state.current_page == "Advantages" || ViewModel.state.current_page == "RaiseBSF") {
			await ViewModel.fetchPage("BSF", this);
			return; 
		}
	}

	render() {
		if (ViewModel.state.loading) {
			return (
				<div>
					<span id="top"></span>
					<Menu
						state={ViewModel.state.menu}
						view={this}
						menuSwitch={ViewModel.menuSwitch}
						data={ViewModel.state.data}
						command={ViewModel.command}
						fetchPage={ViewModel.fetchPage}
						switchPage={ViewModel.switchPage}
						current_page = {ViewModel.state.current_page}
						path={path}
					/>
					
					<TopNavigationBar
						view={this}
						menuSwitch={ViewModel.menuSwitch}
						data={ViewModel.state.data}
						path={path}
						switchPage={ViewModel.switchPage}
						fetchPage={ViewModel.fetchPage}
						current_page = {ViewModel.state.current_page}
					/>
					
						{back_components.includes(ViewModel.state.current_page) ? <div className="back-btn" onClick={() => { this.back() }}>back</div> : null}
						<div id="content">
							{this.conditionalRendering()}
						</div>
					
					
					{ViewModel.state.confirm && ViewModel.state.current_page === "Contact" ?
						<div className="confirm-container">
							<div className="confirm-message">
								<div className="confirm-info">Thank you for contacting us, the request have been received. We will get back to you as soon as possible. </div>
								<div className="confirm-btn" onClick={() => ViewModel.closeConfirmation(this)}>OK</div>
							</div>
						</div>
						: null
					}
					{ViewModel.state.confirm && ViewModel.state.current_page === "OnlineSubmission" ?
						<div className="confirm-container">
							<div className="confirm-message">
								<div className="confirm-info">The work has been uploaded. <br /> Thank you for your participation! </div>
								<div className="confirm-btn" onClick={() => ViewModel.closeConfirmation(this)}>OK</div>
							</div>
						</div>
						: null
					}
					<Footer />
					<a class="circle-btn" href="#top" style={{"display": "flex", "justifyContent": "center", "alignItems": "center","textDecoration":"none"}}>
						<div style={{"textAlign": "center", }}>
							{String.fromCharCode(8593)}<br />
							Top
						</div>
					</a>
				</div>
			)
		}
		return (
			<div className="App"></div>
		);
	}
}

export default View;
