import React, { Component } from 'react';
import '../css/Environment.css';

class Environment extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="Environment-container">
                <img 
                    id="Environment-bg"
                    src={this.props.path + "/Images/BSF/Environment_bg.png"}
                />
                <div id="Environment-description">
                    Kitchen waste is a major environmental concern,
                    with over 3,000 tons generated daily. Black Soldier Fly
                    larvae are highly effective in processing this waste,
                    with 100 grams of larvae capable of treating 1 ton of
                    kitchen waste in one life cycle. By reducing the waste sent
                    to landfills through the use of Black Soldier Fly larvae, we
                    can decrease greenhouse gas emissions, reduce the need for new landfill sites,
                    and promote a cleaner and healthier environment.
                </div>
                <img className="Environment-image" src={this.props.path + "/Images/BSF/BSF_baby.png"} />
            </div>
        )
    }
}

export default Environment; 