// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#menu{
    position:fixed; 
    z-index: 2;
    transition: right 1s; 
    right: 0px; 
    background: white; 
    box-shadow: 0px 0px 10px 5px rgb(151, 150, 150);
    height: 100%; 
    width:40%; 
}

#menu{
    cursor:default; 
}

#menu.off{
    right:-100%; 
    opacity: 1; 
    visibility: visible;
}

#menu-background{
    position: fixed; 
    z-index: 3; 
    height: auto; 
    width: 100%; 
}

#menu-item-container{
    display: flex; 
    flex-direction: column;
    align-items: center;
}

.menu-item{
    color: green; 
    margin-top: 20px; 
    cursor: pointer; 
}

#menu-language{
    display: flex; 
    align-items: center;
    justify-content: space-evenly;
    width: 100%; 
}

#menu-close{
    color: green; 
    margin-right: 20px; 
    margin-top: 20px; 
    align-self: flex-end;
    cursor: pointer; 
}

.current_page{
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
    color: grey; 
}


`, "",{"version":3,"sources":["webpack://./src/View/css/Menu.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,UAAU;IACV,oBAAoB;IACpB,UAAU;IACV,iBAAiB;IACjB,+CAA+C;IAC/C,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,UAAU;IACV,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,sCAA8B;YAA9B,8BAA8B;IAC9B,WAAW;AACf","sourcesContent":["#menu{\n    position:fixed; \n    z-index: 2;\n    transition: right 1s; \n    right: 0px; \n    background: white; \n    box-shadow: 0px 0px 10px 5px rgb(151, 150, 150);\n    height: 100%; \n    width:40%; \n}\n\n#menu{\n    cursor:default; \n}\n\n#menu.off{\n    right:-100%; \n    opacity: 1; \n    visibility: visible;\n}\n\n#menu-background{\n    position: fixed; \n    z-index: 3; \n    height: auto; \n    width: 100%; \n}\n\n#menu-item-container{\n    display: flex; \n    flex-direction: column;\n    align-items: center;\n}\n\n.menu-item{\n    color: green; \n    margin-top: 20px; \n    cursor: pointer; \n}\n\n#menu-language{\n    display: flex; \n    align-items: center;\n    justify-content: space-evenly;\n    width: 100%; \n}\n\n#menu-close{\n    color: green; \n    margin-right: 20px; \n    margin-top: 20px; \n    align-self: flex-end;\n    cursor: pointer; \n}\n\n.current_page{\n    text-decoration: underline 2px;\n    color: grey; \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
