import React, { Component } from 'react';
import './css/Footer.css';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="contact-info">
                <div className="contact-details">
                    <span className="fa-solid fa-phone">&nbsp; </span>
                    <span className="contact-details">+852 5132 2138</span>
                </div>
                <div className="contact-details">
                    <span className="fa-solid fa-envelope">&nbsp; </span>

                    <a className="contact-details" href="mailto: info@ecoenergy-tech.com">
                        info@ecoenergy-tech.com
                    </a>
                </div>
                <div className="contact-details">
                    <span className="contact-details">
                        <span className="fa-solid fa-location-dot" >&nbsp; </span>
                        <span>
                            Unit 1603, 16th Floor, The L. Plaza,<br></br>
                            367 - 375 Queen's Road Central,<br></br>
                            Sheung Wan, Hong Kong
                        </span>
                    </span>
                </div>
            </div>
        )
    }
}

export default Footer; 