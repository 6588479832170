import React, { Component } from 'react';
import './css/News.css';

class News extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="News-container">
                <span className="News-title title">News1</span>
                <div className="News-item">
                    <img className="News-image" src={this.props.path + "/Images/highlights/highlight1.png"} />
                    <div className="flex column" style={{alignItems: "baseline"}}>
                        <span className="News-subtitle subtitle ">Waste into treasure</span>
                        <p className="News-content text">
                            Our company focuses on researching, promoting, 
                            and implementing environmentally sustainable projects and products. 
                            We embody the spirit and will of perseverance, as exemplified by the saying 
                            'constant dripping wears away the stone' and the fable of Yu Gong removed the 
                            mountains. We hope to gather like-minded individuals to protect and sustain our 
                            only home - Earth, and contribute to creating a healthy living environment for 
                            our future generations.
                        </p>
                    </div>
                </div>
                <div className="News-item">
                    <div className="flex column" style={{alignItems: "baseline"}}>
                        <span className="News-subtitle subtitle">"No food waste" opening ceremony</span>
                        <p className="News-content text">
                            Lead the way in sustainable development by researching, 
                            promoting, and implementing environmentally sustainable projects and 
                            products, with the help of biology and technology. We believe that by 
                            harnessing the power of these natural technologies, we can create a better 
                            future for our planet and its inhabitants. We are committed to finding 
                            innovative solutions to the environmental challenges we face, and to making 
                            a positive impact on the world through our work.
                        </p>
                    </div>
                    <img className="News-image" src={this.props.path + "/Images/highlights/highlight2.jpg"} />
                </div>
                <div className="News-item">
                    <img className="News-image" src={this.props.path + "/Images/highlights/highlight3.jpg"} />
                    <div className="flex column" style={{alignItems: "baseline"}}>
                        <span className="News-subtitle subtitle">Black Soldier Fly industry</span>
                        <p className="News-content text">
                            Lead the way in sustainable development by researching, 
                            promoting, and implementing environmentally sustainable projects and 
                            products, with the help of biology and technology. We believe that by 
                            harnessing the power of these natural technologies, we can create a better 
                            future for our planet and its inhabitants. We are committed to finding 
                            innovative solutions to the environmental challenges we face, and to making 
                            a positive impact on the world through our work.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default News; 