/* eslint-disable no-useless-constructor */
import React, {Component} from 'react';
import './css/Highlights.css';

class Highlights extends Component{
    constructor(props){
        super(props);
    }

    render() {
        var product = this.props.data.map((item, index) =>{
            return (
                <div className = "product" key = {index}> 
                    <img 
                        id = {item.product}
                        src = {this.props.path + "/Images/products/" + item.product_icon_src}
                        alt = {item.product_icon_alt}
                        className = "product-icon"
                        onClick = {
                            item.fetch_name == "Education" ? 
                            ()=> this.props.fetchPage(item.fetch_name, this.props.view) :
                            ()=> this.props.command("Products", "GET", `product=${item.fetch_name}`, this.props.view)
                        } 
                    />
                    <div className="product-description">
                        <h4>{item.product_name}</h4>
                        {item.product_description}
                    </div>
                </div>
            )
        })

        return(
            <div id = "product-container"> 
                {product}
            </div>
        )
    }
}

export default Highlights; 