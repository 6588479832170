import React, {Component} from 'react';
import '../css/Lifecycle.css';

class Lifecycle extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div id="Lifecycle-container"> 
                <img id="Lifecycle-image" src={this.props.path + "/Images/BSF/lifecycle.png"} /> 
            </div>
        )
    }
}

export default Lifecycle; 