// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Competition-button{
    width: auto; 
    height: auto; 
    max-height: 150px; 
    margin: 10px; 
    cursor: pointer;
}

#Competition-container{
    display:flex; 
    flex-direction: column;
    align-items: center;
    margin: 40px; 
}

#Competiton-button-container{
    display:flex; 
    flex-direction: column;
    margin: 40px;
}

.Competition-flex{
    display:flex; 
    flex-direction: column;
    align-items: center;
    margin: 20px; 
}

.Competition-image{
    height:auto; 
    width: auto; 
    margin: 20px; 
    max-height: 250px; 
}

@media (min-width: 1000px){
    #Competiton-button-container{
        flex-direction: row;
    }

    .Competition-flex{
        flex-direction: row;
        align-items: center;
        color:rgb(54, 49, 49); 
        justify-content: space-evenly;
    }

    .Competiton-description{
        max-width: 700px; 
    }

    .Competition-button{
        max-width: 30vw; 
    }
}`, "",{"version":3,"sources":["webpack://./src/View/css/Competition.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,mBAAmB;IACvB;;IAEA;QACI,mBAAmB;QACnB,mBAAmB;QACnB,qBAAqB;QACrB,6BAA6B;IACjC;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".Competition-button{\n    width: auto; \n    height: auto; \n    max-height: 150px; \n    margin: 10px; \n    cursor: pointer;\n}\n\n#Competition-container{\n    display:flex; \n    flex-direction: column;\n    align-items: center;\n    margin: 40px; \n}\n\n#Competiton-button-container{\n    display:flex; \n    flex-direction: column;\n    margin: 40px;\n}\n\n.Competition-flex{\n    display:flex; \n    flex-direction: column;\n    align-items: center;\n    margin: 20px; \n}\n\n.Competition-image{\n    height:auto; \n    width: auto; \n    margin: 20px; \n    max-height: 250px; \n}\n\n@media (min-width: 1000px){\n    #Competiton-button-container{\n        flex-direction: row;\n    }\n\n    .Competition-flex{\n        flex-direction: row;\n        align-items: center;\n        color:rgb(54, 49, 49); \n        justify-content: space-evenly;\n    }\n\n    .Competiton-description{\n        max-width: 700px; \n    }\n\n    .Competition-button{\n        max-width: 30vw; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
