import React, { Component } from 'react';
import './css/Competition.css';

class Competition extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="Competition-container">
                <div className="title">
                    Competition
                </div>
                <div className="Competition-flex">
                    <img className="Competition-image" src={this.props.path + "/Images/Competition/champion.png"} />
                    <div className="Competiton-description text">
                        To encourage students to actively participate in competitions,
                        in addition to organizing lectures, workshops, and guided tours
                        to give students a good impression of black soldier flies during
                        the preparation process, we have set up different awards in the
                        competition to give students specific goals and motivation.
                    </div>
                </div>

                <div id="Competiton-button-container">
                    <img
                        className="Competition-button"
                        src={this.props.path + "/Images/Competition/kindergarten.png"}
                        onClick={()=>{this.props.switchPage("Kindergarten", this.props.view)}}
                    />
                    <img
                        className="Competition-button"
                        src={this.props.path + "/Images/Competition/primary.png"}
                        onClick={()=>{this.props.switchPage("Primary", this.props.view)}}
                    />
                    <img
                        className="Competition-button"
                        src={this.props.path + "/Images/Competition/secondary.png"}
                        onClick={()=>{this.props.switchPage("Secondary", this.props.view)}}
                    />

                </div>
            </div>
        )
    }
}

export default Competition; 