import React, {Component} from 'react';
import './css/ProductMenu.css';

class ProductMenu extends Component{
    
    render_function(data){
        var raw_description = data.description.content.split('<br>'); 
        var description = raw_description.map((item, index) =>{
            return(
                <div key = {index} className = "product-menu-description-item">
                    {item} <br></br>
                </div>
            )
        })
        
        var product_groups = data.items.map( (product_group, key) =>{
            var title = Object.keys(product_group);
            var products = product_group[title].map( (product, index) => {
                return (
                    <div key={index} className = "product-menu-product-group-item">
                        <img
                            className = {"product-menu-image " + product.style}
                            src = {this.props.path + "/Images/products/" + product.src}
                            alt = {product.content}
                            onClick = {()=> this.props.command("Products", "GET", `product=${product.fetch_name}`, this.props.view)} 
                        />
                        <div onClick = {()=> this.props.command("Products", "GET", `product=${product.fetch_name}`, this.props.view)} className = "product-menu-product-name">{product.content}</div>
                    </div>
                )
            })
            return (
                <div key = {key}>
                    <div className = "product-menu-product-group-title">{title}</div>
                    <div id="product-menu-product-group-container">
                        {products}
                    </div>
                </div>
            )
            
        })

        return(
            <div>
                <div id = "product-menu-description">
                    {description}
                </div>
                
                <div id= "product-menu-product-groups">
                    <div className= "product-menu-subtitle">Product</div>
                    {product_groups}
                </div>
                
            </div>
        )
    }

    render(){
        return(
            <div id = "product-menu-container">
                {this.render_function(this.props.data.product_menu)}
            </div>
        )
    }
}

export default ProductMenu; 