// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#top-icon{
    height: 4vh;
    width: auto; 
    aspect-ratio: 1.1;
    cursor: pointer; 
}

#top-navigation-bar {
    display: flex;
    position: sticky;
    top: 0px;
    height: 7vh;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px 2px 5px 3px rgb(151, 150, 150);
    background-color: white;
    z-index: 1;  
}

#menu-button{
    position: absolute;
    height: 80%; 
    width: auto;   
    aspect-ratio: 1;
    right: 6.25%; 
    cursor: pointer; 
}

.large-menu-item{
    display: none; 
}

.current_page{
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
    color: grey; 
}

@media (min-width: 1000px){
    .large-menu-item{
        display: inline;
        cursor: pointer; 
        color: green; 
        padding: 0px 10px; 
        font-size: 40px;
        font-weight: bold;
    }

    .current_page{
        -webkit-text-decoration: underline 2px;
                text-decoration: underline 2px;
        color: grey; 
    }

    .large-menu-item:hover{
        background-color: rgb(85, 137, 240); 
        border-radius: 5px;
        color: white; 
        transition: background-color 0.5s; 
    }

    #menu-button{
        display:none; 
    }

    #top-navigation-bar{
        height: 8vh; 
        background: white;
    }

}



`, "",{"version":3,"sources":["webpack://./src/View/css/TopNavigationBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,QAAQ;IACR,WAAW;IACX,mBAAmB;IACnB,6BAA6B;IAC7B,8CAA8C;IAC9C,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,eAAe;IACf,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sCAA8B;YAA9B,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI;QACI,eAAe;QACf,eAAe;QACf,YAAY;QACZ,iBAAiB;QACjB,eAAe;QACf,iBAAiB;IACrB;;IAEA;QACI,sCAA8B;gBAA9B,8BAA8B;QAC9B,WAAW;IACf;;IAEA;QACI,mCAAmC;QACnC,kBAAkB;QAClB,YAAY;QACZ,iCAAiC;IACrC;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,WAAW;QACX,iBAAiB;IACrB;;AAEJ","sourcesContent":["#top-icon{\n    height: 4vh;\n    width: auto; \n    aspect-ratio: 1.1;\n    cursor: pointer; \n}\n\n#top-navigation-bar {\n    display: flex;\n    position: sticky;\n    top: 0px;\n    height: 7vh;\n    align-items: center;\n    justify-content: space-around;\n    box-shadow: 0px 2px 5px 3px rgb(151, 150, 150);\n    background-color: white;\n    z-index: 1;  \n}\n\n#menu-button{\n    position: absolute;\n    height: 80%; \n    width: auto;   \n    aspect-ratio: 1;\n    right: 6.25%; \n    cursor: pointer; \n}\n\n.large-menu-item{\n    display: none; \n}\n\n.current_page{\n    text-decoration: underline 2px;\n    color: grey; \n}\n\n@media (min-width: 1000px){\n    .large-menu-item{\n        display: inline;\n        cursor: pointer; \n        color: green; \n        padding: 0px 10px; \n        font-size: 40px;\n        font-weight: bold;\n    }\n\n    .current_page{\n        text-decoration: underline 2px;\n        color: grey; \n    }\n\n    .large-menu-item:hover{\n        background-color: rgb(85, 137, 240); \n        border-radius: 5px;\n        color: white; \n        transition: background-color 0.5s; \n    }\n\n    #menu-button{\n        display:none; \n    }\n\n    #top-navigation-bar{\n        height: 8vh; \n        background: white;\n    }\n\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
