import React from 'react';
import ReactDOM from 'react-dom/client';
import View from './View';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <View />
);




