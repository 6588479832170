// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Advantages-item-container{
    display: flex; 
    justify-content: space-evenly; 
    height: auto; 
}

.Advantages-item{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    color: white; 
    background-color: rgb(164, 187, 145);
    border-radius: 25px;
    width: auto;
    margin: 20px;
    padding: 20px;
}

#Advantages-img{ 
    align-self: center;
    aspect-ratio: 16/9;
}

@media (min-width: 1000px){
    #Advantages-container{
        margin: 0% 10%; 
    }

    #Advantages-img{ 
        width: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/View/css/Advantages.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;IACZ,oCAAoC;IACpC,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".Advantages-item-container{\n    display: flex; \n    justify-content: space-evenly; \n    height: auto; \n}\n\n.Advantages-item{\n    display: flex; \n    flex-direction: column;\n    justify-content: center;\n    color: white; \n    background-color: rgb(164, 187, 145);\n    border-radius: 25px;\n    width: auto;\n    margin: 20px;\n    padding: 20px;\n}\n\n#Advantages-img{ \n    align-self: center;\n    aspect-ratio: 16/9;\n}\n\n@media (min-width: 1000px){\n    #Advantages-container{\n        margin: 0% 10%; \n    }\n\n    #Advantages-img{ \n        width: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
