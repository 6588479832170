import React, { Component } from 'react';
import './css/Menu.css';

class Menu extends Component {

    async fetch_fun(datum){
        await this.props.fetchPage(datum.fetch_name, this.props.view); 
        this.props.menuSwitch(this.props.view); 
    }

    render_fun(data){
        return(
            data.map( (datum, key) =>{
                return (
                    <span 
                        key = {key}
                        className = {"menu-item" + (this.props.current_page === datum.fetch_name ? " current_page" : "")}
                        onClick = {() => this.fetch_fun(datum)}
                    >
                        {datum.display_name}
                    </span>
                )
            })
        )
    }

    render() {
        console.log(this.props.state)
        return (
            <div id="menu" className = {this.props.state}>
                <div id="menu-background" className = {this.props.state}></div>
                <div id="menu-item-container">
                    <div 
                        id="menu-close"
                        onClick = {() => this.props.menuSwitch(this.props.view)}
                    >
                        X
                    </div>
                    {this.render_fun(this.props.data.menu_item)}
                    <div id = "menu-language">
                        <span className = "menu-item">中</span>
                        <span className = "menu-item cursor-default">|</span>
                        <span className = "menu-item">EN</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu; 