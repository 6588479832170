import React, { Component } from 'react';
import '../css/Advantages.css';

class Advantages extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="Advantages-container">
                <div className="title" style={{"textAlign": "center"}}>Advantages</div>
                <dl>
                    <div className="Advantages-item-container">
                        <div className="Advantages-item">
                            <dt>Low cost of breeding: </dt>
                            <dd>
                                Breeding black soldier flies is
                                relatively inexpensive compared to
                                traditional animal farming methods.
                            </dd>
                        </div>
                        <div className="Advantages-item">
                            <dt>Frass can be used as organic fertilizer:</dt>
                            <dd>
                                The waste produced by black soldier fly larvae,
                                known as frass, can be used as an organic fertilizer.
                            </dd>
                        </div>
                    </div>

                    <div className="Advantages-item-container">
                        <div className="Advantages-item">
                            <dt>Substitute for fish meal: </dt>
                            <dd>Black soldier flies can be used as a substitute for fish meal, helping to alleviate the crisis of overfishing in the seafood industry.</dd>
                        </div>
                        <div className="Advantages-item">
                            <dt>High absorption and conversion rate: </dt>
                            <dd>Black soldier flies can efficiently convert the waste they consume into valuable protein.</dd>
                        </div>
                    </div>

                    <div className="Advantages-item-container">
                        <div className="Advantages-item">
                            <dt>Rapid reproduction: </dt>
                            <dd>The short breeding cycle of black soldier fliesand a single female can lay up to 1000 eggs.</dd>
                        </div>
                        <img id="Advantages-img" src={this.props.path + "/Images/BSF/environmental_protection.png"} />
                        <div className="Advantages-item">
                            <dt>Good palatability for animals:</dt>
                            <dd>Black soldier fly larvae are a nutritious and palatable source of protein for many animals.</dd>
                        </div>
                    </div>

                    <div className="Advantages-item-container">
                        <div className="Advantages-item">
                            <dt>Omnivorous: </dt>
                            <dd>Black soldier flies have a broad diet, preferring organic waste, but they can also eat vegetables, fruits, kitchen scraps, weeds, and grass.</dd>
                        </div>
                        <div className="Advantages-item">
                            <dt>Highly adaptable and safe to breed: </dt>
                            <dd>Black soldier flies have strong immunity and can adapt to different environments, making them safe to breed without invading human environments.</dd>
                        </div>
                    </div>
                </dl>
            </div>
        )
    }
}

export default Advantages; 