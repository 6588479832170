// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-pic {
    width: 100%; 
    height: auto; 
    aspect-ratio: 252/140 ; 
}

#highlight {
    display: flex;
    flex-direction: row;
    position: relative; 
    width:80%; 
    height: -moz-fit-content; 
    height: fit-content; 
    align-items: center;
    justify-content:center; 
}

#highlight-button-container {
    display:flex; 
    align-items: center; 
    position: absolute;
    width: 100%;
    height: 100%; 
}

#highlight-prev, #highlight-next {
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    font-size: 30px;
    color: black; 
}


#highlight-next {
    right:0; 
}

@media (min-width: 1000px){
    #highlight {
        display: flex;
        flex-direction: row;
        position: relative; 
        width:80%; 
        height: -moz-fit-content; 
        height: fit-content; 
        align-items: center;
        justify-content:center; 
    }
}`, "",{"version":3,"sources":["webpack://./src/View/css/SlideShow.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,wBAAmB;IAAnB,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,6BAA6B;IAC7B,yBAAyB;IACzB,eAAe;IACf,eAAe;IACf,YAAY;AAChB;;;AAGA;IACI,OAAO;AACX;;AAEA;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,kBAAkB;QAClB,SAAS;QACT,wBAAmB;QAAnB,mBAAmB;QACnB,mBAAmB;QACnB,sBAAsB;IAC1B;AACJ","sourcesContent":[".highlight-pic {\n    width: 100%; \n    height: auto; \n    aspect-ratio: 252/140 ; \n}\n\n#highlight {\n    display: flex;\n    flex-direction: row;\n    position: relative; \n    width:80%; \n    height: fit-content; \n    align-items: center;\n    justify-content:center; \n}\n\n#highlight-button-container {\n    display:flex; \n    align-items: center; \n    position: absolute;\n    width: 100%;\n    height: 100%; \n}\n\n#highlight-prev, #highlight-next {\n    height: fit-content;\n    position: absolute;\n    background-color: transparent;\n    border-color: transparent;\n    cursor: pointer;\n    font-size: 30px;\n    color: black; \n}\n\n\n#highlight-next {\n    right:0; \n}\n\n@media (min-width: 1000px){\n    #highlight {\n        display: flex;\n        flex-direction: row;\n        position: relative; \n        width:80%; \n        height: fit-content; \n        align-items: center;\n        justify-content:center; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
