// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#contact-info {
    font-size: 20px;
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    align-self: center;
    box-shadow: 0px -3px 5px 1px rgb(151, 150, 150);
    margin: 0px;
    background-color: white;
    z-index: 2; 
}`, "",{"version":3,"sources":["webpack://./src/View/css/Footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,wBAAmB;IAAnB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,+CAA+C;IAC/C,WAAW;IACX,uBAAuB;IACvB,UAAU;AACd","sourcesContent":["#contact-info {\n    font-size: 20px;\n    display: flex;\n    height: fit-content;\n    flex-direction: column;\n    align-self: center;\n    box-shadow: 0px -3px 5px 1px rgb(151, 150, 150);\n    margin: 0px;\n    background-color: white;\n    z-index: 2; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
