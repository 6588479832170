import React, { Component } from 'react';
import './css/Education.css';

class Education extends Component {
    output_format(data) {
        var education_data = data.Education;

        var output = education_data.map((item, index) => {
            if (index % 2 === 0) {

                return (
                    <div key={index} className="Education-item" onClick={() => this.props.fetchPage(item.fetch_name, this.props.view)}>
                        <img
                            className="Education-image"
                            src={this.props.path + "/Images/Education/" + item.image}
                            alt=""
                        />
                        <span className="Education-description subtitle"> {item.description} </span>
                    </div>
                )
            }
            else {

                return (
                    <div key={index} className="Education-item" onClick={() => this.props.fetchPage(item.fetch_name, this.props.view)}>
                        <span className="Education-description subtitle"> {item.description} </span>
                        <img
                            className="Education-image"
                            src={this.props.path + "/Images/Education/" + item.image}
                            alt=""
                        />
                    </div>
                )
            }
        })

        return output;
    }

    render() {
        return (
            <div>
                <div id="Education">
                    <h4 id="Education-title" className="title">Education</h4>
                    <div className="Education-grid">
                        {this.output_format(this.props.data)}
                    </div>
                </div>
                <img id="Education-bg"
                    src={this.props.path + "/Images/background2.png"}
                />
            </div>

        )
    }
}

export default Education; 