import React, { Component } from "react";
import "./css/OnlineSubmission.css";
import ViewModel from '../ViewModel.js'

class OnlineSubmission extends Component {
    constructor(props) {
        super(props);
        this.name_input = React.createRef();
        this.male_input = React.createRef();
        this.female_input = React.createRef();
        this.age_input = React.createRef();
        this.group_input = React.createRef();
        this.school_input = React.createRef();
        this.phone_input = React.createRef();
        this.class_input = React.createRef();
        this.pdf_input = React.createRef();
        this.confirmCheckbox = React.createRef(); 
        this.state = {
            confirm: false, 
            data: ""
        }
        this.formValidation = this.formValidation.bind(this); 
        this.terms = this.terms.bind(this); 
        this.submitForm = this.submitForm.bind(this); 
    }

    formValidation(){
        var data = new FormData();
        var participant_gender = null;
        var participant_name = this.name_input.current.value;
        
        if (this.male_input.current.value !== this.female_input.current.value) {
            participant_gender = this.male_input.current.checked ? this.male_input.current.value : this.female_input.current.value
        }
        
        var participant_age = this.age_input.current.value;
        var participant_group = this.group_input.current.value;
        var participant_school = this.school_input.current.value;
        var participant_phone = this.phone_input.current.value;
        var participant_class = this.class_input.current.value;
        var participant_pdf = this.pdf_input.current.files[0];
        
        if(participant_name ===""){
            window.alert("Please enter your name"); 
            return false;
        }
        if(participant_age === ""){
            window.alert("Please enter your age"); 
            return false; 
        }
        if(participant_group ===""){
            window.alert("Please select your group")
            return false; 
        }
        if(participant_school===""){
            window.alert("Please enter your school"); 
            return false; 
        }
        if(participant_phone===""){
            window.alert("Please enter your phone number"); 
            return false; 
        }
        if(participant_class===""){
            window.alert("Please enter your class"); 
            return false; 
        }
        if(participant_pdf === undefined){
            window.alert("Please attach your file");
            return false;
        }

        data.append("file", participant_pdf);
        data.append("participant_name", participant_name);
        data.append("participant_age", participant_age);
        data.append("participant_group", participant_group);
        data.append("participant_school", participant_school);
        data.append("participant_gender", participant_gender);
        data.append("participant_phone", participant_phone);
        data.append("participant_class", participant_class);

        this.setState({
            data: data
        }); 
        return true; 
    }

    terms(){
        if(this.formValidation()){
            this.setState({
                confirm: true, 
            })
        }; 
        
    }

    async submitForm(){
        const page = "OnlineSubmission";
        if (this.confirmCheckbox.current.checked){
            this.setState({
                confirm: false,
            })
        }
        else{
            window.alert("Please read all terms and conditions")
            return; 
        }
        await this.props.fileCommand(page, this.state.data, this.props.view);
        this.name_input.current.value = null; 
        this.age_input.current.value = null; 
        this.group_input.current.value = null; 
        this.school_input.current.value = null; 
        this.phone_input.current.value = null; 
        this.class_input.current.value = null; 
        this.pdf_input.current.value = null; 
    }


    render() {
        return (
            <div id="OnlineSubmission-container">
                <div id="OnlineSubmission-title">
                    Competition
                </div>
                <div className="OnlineSubmission-subtitle1">
                    Online submission
                </div>
                <div className="OnlineSubmission-subtitle2">
                    Entrant information
                </div>
                <div id="OnlineSubmission-form">
                    <span className="OnlineSubmission-form-subtitle">
                        English Name (same as Hong Kong Identity Card/Passport)<span style={{"color": "red"}}>*</span>
                        
                    </span>
                    <input type="text" ref={this.name_input} />
                    <span id="OnlineSubmission-gender" className="OnlineSubmission-form-subtitle">Gender</span>
                    <div className="OnlineSubmission-flex1">
                        <div className="OnlineSubmission-flex1 OnlineSubmission-gender-item">
                            <input type="radio" id="gender1" name="gender" value="M" ref={this.male_input} />
                            <label >M</label>
                        </div>
                        <div className="OnlineSubmission-flex1 OnlineSubmission-gender-item">
                            <input type="radio" id="gender2" name="gender" value="F" ref={this.female_input} />
                            <label >F</label>
                        </div>
                    </div>
                    <span className="OnlineSubmission-form-subtitle">
                        Age<span style={{"color": "red"}}>*</span>
                    </span>
                    <input type="text" ref={this.age_input} />
                    <span className="OnlineSubmission-form-subtitle">
                        Group<span style={{"color": "red"}}>*</span>
                    </span>
                    <select className= "OnlineSubmission-form-list" ref={this.group_input} >
                        <option value= ""> -Please Select Your Group</option>
                        <option value= "Kindergarten">Kindergarten</option>
                        <option value= "Primary">Primary</option>
                        <option value= "Secondary">Secondary</option>
                    </select>
                    <span className="OnlineSubmission-form-subtitle">
                        Name of School<span style={{"color": "red"}}>*</span>
                    </span>
                    <input type="text" ref={this.school_input} />
                    <span className="OnlineSubmission-form-subtitle">
                        Phone number<span style={{"color": "red"}}>*</span>
                    </span>
                    <input type="text" ref={this.phone_input} />
                    <span className="OnlineSubmission-form-subtitle">
                        Class<span style={{"color": "red"}}>*</span>
                    </span>
                    <input type="text" ref={this.class_input} />
                    <span className="OnlineSubmission-form-subtitle">
                        Upload PDF/PNG/JPG file<span style={{"color": "red"}}>*</span>
                    </span>
                    <input type="file" ref={this.pdf_input} />
                </div>
                <img className = "OnlineSubmission-btn" src={this.props.path + "/Images/Competition/btn.png"} onClick={this.terms} /> 
                {this.state.confirm === true ? 
                <div id="OnlineSubmission-terms">
                    <div 
                        onClick = {() => this.setState({confirm: false})}
                        style = {{
                            "cursor": "pointer", 
                            "fontSize": "40px", 
                            "width": "fit-content", 
                            "alignSelf": "flex-end"
                            
                        }}
                    >
                        <span class="fa fa-window-close" ></span>
                    </div>
                    <div className="OnlineSubmission-flex2">
                        <div className="OnlineSubmission-subtitle2">
                            Terms and Conditions
                        </div>
                        <ul>
                            <li>
                                Each entrant may only submit one entry. The same entry may not be submitted more than once.
                            </li>
                            <li>
                                Once an entry is submitted, it cannot be modified or replaced and will not be returned to the entrant.
                            </li>
                            <li>
                                The Organisers will not notify individual entrant upon receipt of entries. All submitted entries will not be returned.
                            </li>
                            <li>
                                Computer-aided design is not allowed for the Colouring Competition. Apart from using drawing tools, finger painting is welcome.
                            </li>
                            <li>
                                An entry should not contain any material that is obscene, violent, pornographic, defamatory, indecent, disparaging or content that is controversial and inappropriate.
                            </li>
                            <li>
                                The Organisers have the right to reject entries that are inappropriate, irrelevant to the theme of the Competition or in the wrong format.
                            </li>
                            <li>
                                Entries must not promote any commercial enterprise or political cause.
                            </li>
                            <li>
                                All entries must be the original work of the entrants and do not infringe upon the rights (including intellectual property rights, rights of privacy or confidentiality) of any other parties. Otherwise, they would be disqualified. Entrants are responsible for all legal liabilities. Should an entry violate any laws, the Organisers would not be held legally responsible. The related entrant shall indemnify and keep the Organisers and their authorised users fully and effectively indemnified against any and all actions, damages, costs, claims, demands, expenses (including the fees and disbursements of lawyers, agents and expert witnesses) and any awards and costs which may be agreed to be paid in settlement of any proceedings and liabilities of any nature.
                            </li>
                            <li>
                                The decisions of the Assessment Panel on the Competition results shall be final. Entrants shall raise no objection to the Competition results or rankings.
                            </li>
                            <li>
                                The right to receive a prize is non-assignable and non-transferable.
                            </li>
                            <li>
                                By submitting an entry in the Competition, each entrant agrees to be bound by the Terms and Conditions. The Organisers reserve the right to disqualify any entrant or retract any award from an entrant who it believes has breached any of the Terms and Conditions. No appeals will be entertained.
                            </li>
                        </ul>
                    </div>
                    <div className="OnlineSubmission-flex2">
                        <div className="OnlineSubmission-subtitle2">
                            Collection of Personal Data
                        </div>
                        <ul>
                            <li>
                                The personal data is provided by the participant voluntarily for the Competition. The information will be provided to relevant staff for application processing, contact and other relevant publicity purposes. Except with the consent of entrants, all the personal data collected will be destroyed within 6 months after the completion of the Competition.
                            </li>
                            <li>
                                The personal data provided by the entrants are mainly for use by the Organiser but they may also be disclosed to other Government bureaux/departments or relevant parties for the purposes mentioned in this Competition, if required. Apart from this, the data may only be disclosed to parties which the entrants concerned have given consent to such disclosure or where such disclosure is allowed under the Personal Data (Privacy) Ordinance.
                            </li>
                        </ul>
                    </div>
                    <div> 
                        <input type="checkbox" ref={this.confirmCheckbox}/> I have read all terms and conditions. 
                    </div>
                    <img className = "OnlineSubmission-btn" src={this.props.path + "/Images/Competition/btn.png"} onClick={this.submitForm} /> 
                </div> : null}
            </div>
        )
    }
}

export default OnlineSubmission; 