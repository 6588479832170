import React, {Component} from 'react';
import '../css/Technology.css';

class Testing extends Component{
    constructor(props){
        super(props);
    }


    render(){
        var product = this.props.data.description.product_name; 
        var product_title = this.props.data.title.content;
        return(
            <div className="tech-container"> 
                <div className="tech-title">
                    {product_title}
                </div>
                <div className = "tech-subtitle">
                    Decomposition Processing
                </div>
                <img 
                    src= {this.props.path + "/Images/Technology/" + product + "/1.png"}
                    className = "tech-img"
                />
                <img className = "tech-img" src= {this.props.path + "/Images/Technology/" + product + "/2.png"}/>

                <div className = "tech-description">
                    Alpha-Gaia is a system that can 
                    instantly decompose cell walls that 
                    could not be decomposed by strong acid, 
                    alkali, high temperature, or high pressure 
                    by bringing active oxygen generated by AOS 
                    and organic matter into contact with an agitator 
                    at room temperature in air and decomposing them.
                </div>

                <div className = "tech-info">
                    Pre-treatment of fermentation completes the process 
                    two to five times faster!
                </div>
                
                <img className = "tech-img" src= {this.props.path + "/Images/Technology/" + product + "/3.png"}/>
            </div>
        )
    }
}

export default Testing; 