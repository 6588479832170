// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#GuidedTour-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px;
    color: green;
}

#GuidedTour-title{
    align-self: baseline ;
    position: relative; 
    left:50px; 
    font-size: 40px; 
    font-weight: bold; 
}

.GuidedTour-image{
    height:auto; 
    width: auto; 
    max-height: 300px;
    max-width: 400px; 
    align-self: center;
}

.GuidedTour-description2{
    line-height: 2; 
}

.GuidedTour-flex{
    display:flex; 
    flex-direction: column;
    justify-content: space-evenly;
}

.GuidedTour-flex1{
    display:flex; 
    align-items: center;
    margin: 20px; 
    justify-content: space-evenly;
}

#GuidedTour-booking{
    height: auto; 
    width:auto; 
    max-height: 100px; 
    margin-top: 20px; 
    cursor: pointer; 
}

@media (min-width: 1000px){
    .GuidedTour-flex{
        flex-direction: row;
    }

    .GuidedTour-flex1{
        flex-direction: row-reverse; 
    }

    .GuidedTour-image{
        height: auto; 
        width: 35vw; 
        max-height: unset;
        max-width: unset; 
        align-self: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/View/css/GuidedTour.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI;QACI,mBAAmB;IACvB;;IAEA;QACI,2BAA2B;IAC/B;;IAEA;QACI,YAAY;QACZ,WAAW;QACX,iBAAiB;QACjB,gBAAgB;QAChB,kBAAkB;IACtB;AACJ","sourcesContent":["#GuidedTour-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 40px;\n    color: green;\n}\n\n#GuidedTour-title{\n    align-self: baseline ;\n    position: relative; \n    left:50px; \n    font-size: 40px; \n    font-weight: bold; \n}\n\n.GuidedTour-image{\n    height:auto; \n    width: auto; \n    max-height: 300px;\n    max-width: 400px; \n    align-self: center;\n}\n\n.GuidedTour-description2{\n    line-height: 2; \n}\n\n.GuidedTour-flex{\n    display:flex; \n    flex-direction: column;\n    justify-content: space-evenly;\n}\n\n.GuidedTour-flex1{\n    display:flex; \n    align-items: center;\n    margin: 20px; \n    justify-content: space-evenly;\n}\n\n#GuidedTour-booking{\n    height: auto; \n    width:auto; \n    max-height: 100px; \n    margin-top: 20px; \n    cursor: pointer; \n}\n\n@media (min-width: 1000px){\n    .GuidedTour-flex{\n        flex-direction: row;\n    }\n\n    .GuidedTour-flex1{\n        flex-direction: row-reverse; \n    }\n\n    .GuidedTour-image{\n        height: auto; \n        width: 35vw; \n        max-height: unset;\n        max-width: unset; \n        align-self: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
