import React, { Component } from 'react';
import './css/Home.css';
import SlideShow from './SlideShow.js';
import Highlights from './Highlights.js'

class Home extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            QA1: "on", 
            QA2: "on",  
        }
    }
    render() {
        return (
            <div id="home-content">
                <SlideShow
                    data={this.props.data.highlights}
                    path={this.props.path}
                />
                <div 
                    className="button" 
                    style={{
                        width: "fit-content", 
                        padding: "10px 50px", 
                        borderRadius: "0px"
                    }} 
                    onClick={()=>this.props.switchPage("News", this.props.view)}
                >
                    More
                </div>
                <div className="home-subtitle">
                    Do you want to live in a garbage-filled city?
                </div>
                <div id="circle-container">
                    <img
                        src={this.props.path + "/Images/trash/trash1.jpg"}
                        className="circle"
                        alt=""
                    />
                    <img
                        src={this.props.path + "/Images/trash/trash2.jpg"}
                        className="circle"
                        alt=""
                    />
                    <img
                        src={this.props.path + "/Images/trash/trash3.jpg"}
                        className="circle"
                        alt=""
                    />
                </div>
                <div id = "home-description">
                    Poor waste management in cities not only harms the environment but also affects the daily lives of residents.
                    It's crucial to reflect on these problems and take action to reduce waste and improve our cities' quality of life.
                </div>
                <div className="content-container">
                    <div className="QA">
                        <span className={this.state.QA1 + " blur"} onClick={() => this.setState({QA1:"off"}) }>
                            Which Asian country generates the highest amount of daily domestic waste per capita?
                        </span>
                        <img
                            className="QA-image"
                            id="QA1"
                            src={this.props.path + "/Images/QA/QA1.png"}
                            onClick={() => this.setState({QA1: "on"}) }
                            alt= ""
                        />
                    </div>
                    <span className="vertical"> DO YOU KNOW...</span>
                </div>
                <div className="content-container2">
                    <img
                        id = "QA"
                        src={this.props.path + "/Images/QA/QA.png"}
                        alt=""
                    />
                    <div className="QA">
                        <span className={this.state.QA2 + " blur"} onClick={() => this.setState({ QA2: "off" })}>
                            Which municipal solid waste (MSW) is in the top 3 with the highest percentage in Hong Kong?
                        </span>
                        <img
                            id = "QA2"
                            className = "QA-image"
                            src={this.props.path + "/Images/QA/QA2.png"}
                            onClick={() => this.setState({ QA2: "on" })}
                            alt=""
                        />
                    </div>
                </div>
                <p className= "home-subtitle2">
                    In addition to reducing waste at the source,
                    our company's technology and research can also help protect the environment.
                </p>
                <Highlights
                    view={this.props.view}
                    data={this.props.data.products}
                    path={this.props.path}
                    switchPage={this.props.switchPage}
                    fetchPage = {this.props.fetchPage}
                    command = {this.props.command}
                />
            </div>
        )
    }
}

export default Home; 