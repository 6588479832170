// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Environment-container{
    display: flex;
    height: 100%; 
    width: 100%; 
    min-height:70vh;
    align-items: center;
    justify-content: space-evenly; 
    

}

.Environment-image{
    width: 45vw; 
    height: auto;
}

#Environment-bg{
    position: absolute;
    height: auto; 
    width: 100%; 
    bottom: 0px; 
    z-index: -1; 
}

#Environment-description{
    width: 50vw; 
    color: green; 
}`, "",{"version":3,"sources":["webpack://./src/View/css/Environment.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,6BAA6B;;;AAGjC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,WAAW;IACX,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["#Environment-container{\n    display: flex;\n    height: 100%; \n    width: 100%; \n    min-height:70vh;\n    align-items: center;\n    justify-content: space-evenly; \n    \n\n}\n\n.Environment-image{\n    width: 45vw; \n    height: auto;\n}\n\n#Environment-bg{\n    position: absolute;\n    height: auto; \n    width: 100%; \n    bottom: 0px; \n    z-index: -1; \n}\n\n#Environment-description{\n    width: 50vw; \n    color: green; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
