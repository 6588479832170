import React, { Component } from 'react';
import '../css/RaiseBSF.css';

class RaiseBSF extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="RaiseBSF-container">
                <div className="RaiseBSF-item RaiseBSF-row">
                    <img className="RaiseBSF-img" style={{ aspectRatio: "0.8", height: "15vh" }} src={this.props.path + "/Images/BSF/bottle.png"} />
                    <ul>
                        <li>
                            The container should be kept in a shaded area.
                            Keep the substrate moist and warm.
                            The ideal temperature range is between 25-35°C (77-95°F).
                            The substrate should be kept moist, but not too wet,
                            to prevent mold growth.
                        </li>
                    </ul>
                    <img className="RaiseBSF-img" src={this.props.path + "/Images/BSF/eggs.png"} />
                </div>
                <div className="RaiseBSF-row">
                    <img className="RaiseBSF-img" style={{ "alignSelf": "flex-start" }} src={this.props.path + "/Images/BSF/food1.png"} />
                    <div className="RaiseBSF-item">
                        <ul>
                            <li>
                                After hatching, the larvae will consume the substrate and grow quickly.
                                It is essential to provide enough food for the larvae to consume,
                                such as kitchen waste or animal manure.
                            </li>
                        </ul>
                        <img className="RaiseBSF-img" src={this.props.path + "/Images/BSF/worm.png"} />
                    </div>
                </div>
                <div className="RaiseBSF-row">
                    <div className="RaiseBSF-item">
                        <ul>
                            <li>
                                Directly feeding the larvae with kitchen waste is an effective way to raise them.
                                The kitchen waste should have a moisture content of 65%-90%,
                                and it should be chopped or blended into small pieces to make it easier for the larvae to consume.
                            </li>
                        </ul>
                        <img className="RaiseBSF-img" src={this.props.path + "/Images/BSF/cookie.png"} />
                    </div>
                    <img className="RaiseBSF-img" style={{ "alignSelf": "flex-start" }} src={this.props.path + "/Images/BSF/midge.png"} />
                </div>
                <div className="RaiseBSF-row">
                    <div className="RaiseBSF-item">
                        <img className="RaiseBSF-img" src={this.props.path + "/Images/BSF/fly.png"} />
                        <ul>
                            <li>
                                After 8-10 days, the kitchen waste will be converted
                                into insect body feed/organic raw material frass.
                                The frass can be collected and used as a nutrient-rich organic fertilizer.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default RaiseBSF; 