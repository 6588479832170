import React, { Component } from 'react';
import '../css/Kindergarten.css';

class Kindergarten extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="Kindergarten-container">
                <div id="Kindergarten-title" className="title">
                    Competition
                </div>
                <div className="Kindergarten-item1">
                    <div className="Kindergarten-subtitle1 subtitle">
                        Kindergarten
                    </div>
                    <img
                        className="Kindergarten-img"
                        src={this.props.path + "/Images/Competition/Kindergarten/Competition.png"}
                    />
                    <a
                        
                        href={this.props.path + "/Images/Competition/Kindergarten/Competition.pdf"}
                        target="_blank"
                    >
                        <img 
                            className="Kindergarten-btn"
                            src={this.props.path + "/Images/Competition/download.png"} 
                        />
                    </a>
                    <div className="Kindergarten-subtitle2 subtitle">
                        Colouring Competition
                    </div>
                </div>

                <div className="Kindergarten-info">
                    <div className="Kindergarten-left">
                        <div className="Kindergarten-item1">
                            <div className="Kindergarten-subtitle3">Aims</div>
                            <ul>
                                <li>Promotes creativity and environmental awareness</li>
                                <li>Improve the public image of the Black Soldier Fly</li>
                                <li>Dispel negative perceptions </li>
                            </ul>
                        </div>
                        <div className="Kindergarten-item1">
                            <div className="Kindergarten-subtitle3">Prizes: </div>
                            <div className="Kindergarten-subtitle4">Champion x 1</div>
                            <div>Certificate + $1000 Super Market Cash Voucher</div>
                            <div className="Kindergarten-subtitle4">1st Runner-up x 1</div>
                            <div>Certificate + $500 Super Market Cash Voucher</div>
                            <div className="Kindergarten-subtitle4">2nd Runner-up x 1</div>
                            <div>Certificate + $100 Super Market Cash Voucher</div>
                            <div className="Kindergarten-subtitle4">Merit Award x 10</div>
                            <div>Certificate + $50 Super Market Cash Voucher</div>
                        </div>
                    </div>
                    <div className="Kindergarten-right">
                        <div className="Kindergarten-item2">
                            <div id="Kindergarten-group" className="Kindergarten-subtitle3">Group:</div> K1-K3 student
                        </div>
                        <div className="Kindergarten-item">
                            <div className="Kindergarten-subtitle3">Assessment Criteria:</div>
                            <div>Drawing Skills and Appropriate Use of Colour 30%</div>
                            <div>Design and Creativeness 30%</div>
                            <div>Overall Aesthetics 40%</div>
                        </div>
                        <div className="Kindergarten-item2">
                            <div id="Kindergarten-expire" className="Kindergarten-subtitle3">Expiration date: </div>
                            <div>31/12/2023</div>
                        </div>
                        <div className="Kindergarten-item">
                            <div className="Kindergarten-subtitle3">Submission of Entries: </div>
                            <ul>
                                <li>School collection box </li>
                                <li>Online submission</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <img 
                    className="Kindergarten-btn" 
                    src={this.props.path + "/Images/Competition/submission.png"} 
                    onClick ={() => {this.props.switchPage("OnlineSubmission", this.props.view)}}
                />
            </div>
        )
    }
}

export default Kindergarten; 