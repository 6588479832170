import React, { Component } from 'react';
import View from "./View.js"
const local_server = false; 
const server_address = local_server ? "http://192.168.3.10:45221" : "https://ecoenergy-tech.com/db";

class Model extends Component {
    async fetchPage(page){
        try {
            var response = await fetch(`${server_address}/${page}`); 
            var json = await response.json(); 
            console.log(json); 
            return json; 
        } catch(e) {
            console.log(e); 
        }
    }

    async fileCommand(page, data){
        var options = {
            method: "POST", 
            body: data
        }
        try {
            var response = await fetch(`${server_address}/${page}`, options); 
            var json = await response.json(); 
            return json; 
        } catch(e) {
            console.log(e); 
        }
    }

    async command(page, method, data){
        var response, json; 
        switch(method){
            case "POST":
                var options = {
                    method: method, 
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data)
                }; 
                try {
                    response = await fetch(`${server_address}/${page}`, options); 
                    json = await response.json(); 
                    return json; 
                } catch(e) {
                    console.log(e); 
                }
            case "GET":
                try {
                    response = await fetch(`${server_address}/${page}?${data}`)
                    json = await response.json(); 
                    return json; 
                } catch(e){ 
                    console.log(e); 
                }
                
        }
    }
}

Model = new Model(); 
export default Model; 