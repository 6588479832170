import React, { Component } from 'react';
import '../css/Technology.css';

class Testing extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var product_title = this.props.data.title.content;
        var product = this.props.data.description.product_name; 
        return (
            <div className = "tech-container">
                <div className="tech-title">
                    {product_title}
                </div>
                <div className = "tech-subtitle">
                    Decomposition Processing
                </div>
                <img className = "tech-img" src={this.props.path + "/Images/Technology/" + product + "/1.png"} />
                <div className = "tech-description2">
                    <ol>
                        <li>After closing the top cover, waste drops down to the decomposition zone. </li>
                        <li>Irradiate by magnetized air to low temperature pyrolysis and carbonization.</li>
                        <li>Ash on the bottom.</li>
                    </ol>
                    *Ash volume is 1/300 of the original material
                </div>

                <div className = "tech-subtitle">
                    Main features of Super Waste Processor
                </div>
                <img className = "tech-img" src={this.props.path + "/Images/Technology/" + product + "/2.png"} />
                <div className = "tech-description2">
                    <ul>
                        <li>Can be used anywhere as long as there is a roof and electricity.</li>
                        <li>Smoked only with electricity without any fuel such as gas and oil.</li>  
                        <li>Electricity costs about US$400 to US$800 per month.</li> 
                        <li>Does not emit exhaust gas.</li> 
                        <li>High thermal efficiency.</li>    
                        <li>The volume of ash is reduced to 1/100 to 1/300 of the original waste.</li>    
                        <li>Since only a small amount ash is produced, it is easy to scrape the ash once every 2 to 3 weeks.</li>    
                        <li>Efficient waste disposal is possible because waste can be continuously charged.</li>
                        <li>Since the structure of SWP is small and simple, there is no failure and maintenance is very easy.</li>  
                        <li>Garbage such as diapers, food waste and livestock droppings those contains waters can be treated by mixing with flammable waste.</li>  
                        <li>Super Waste Processor is 100% made in Japan</li>
                    </ul>
                </div>


            </div>
        )
    }
}

export default Testing; 