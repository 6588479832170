import React, {Component} from 'react';
import '../css/Technology.css';

class Testing extends Component{
    constructor(props){
        super(props);
    }

    render(){
        var product = this.props.data.description.product_name; 
        var product_title = this.props.data.title.content;
        return(
            <div className="tech-container"> 
                <div className="tech-title">
                    {product_title}
                </div>
                <div className = "tech-subtitle">
                    Decomposition Processing
                </div>
                <img 
                    src= {this.props.path + "/Images/Technology/" + product + "/1.png"}
                    className = "tech-img"
                />
                

                <div className = "tech-description">
                    As shown in the figure below right, 
                    the ceramic powder at the bottom is heated to 200 to 
                    300°C while active oxygen is supplied from both sides. 
                    When the waste is thrown in, the active oxygen causes drying 
                    and carbonization, and the decomposition process proceeds. 
                    When the amount of waste decreases, additional waste is fed in 
                    through the feed port. Since no fire is used, there is no "ignition. 
                    When the waste is removed after about half a day, 
                    ceramic-like ash and metals that cannot be processed remain.
                </div>

                <div className = "tech-subtitle">
                    Decomposes general house waste, plastic, PET bottles, medical waste and diapers
                </div>
                
                <img className = "tech-img" src= {this.props.path + "/Images/Technology/" + product + "/2.png"}/>
            </div>
        )
    }
}

export default Testing; 