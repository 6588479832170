import React, { Component } from 'react';
import './css/About.css';

class About extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="About-container">
                <span className="About-title title">About Us</span>
                <div className="About-item">
                    <img className="About-image" src={this.props.path + "/Images/About/grass.png"} />
                    <div className="flex column">
                        <span className="About-subtitle subtitle ">Our Vision</span>
                        <p className="About-content text">
                            Our company focuses on researching, promoting, and implementing environmentally sustainable projects and products. We embody the spirit and will of perseverance, as exemplified by the saying 'constant dripping wears away the stone' and the fable of Yu Gong removed the mountains. We hope to gather like-minded individuals to protect and sustain our only home - Earth, and contribute to creating a healthy living environment for our future generations.
                        </p>
                    </div>
                </div>

                <div className="About-item">
                    <div className="flex column">
                        <span className="About-subtitle subtitle">Our Mission</span>
                        <p className="About-content text">
                            Lead the way in sustainable development by researching, promoting, and implementing environmentally sustainable projects and products, with the help of biology and technology. We believe that by harnessing the power of these natural technologies, we can create a better future for our planet and its inhabitants. We are committed to finding innovative solutions to the environmental challenges we face, and to making a positive impact on the world through our work.
                        </p>
                    </div>

                    <img className="About-image" src={this.props.path + "/Images/About/glass.png"} />
                </div>

                <div id="About-goal-container">
                    <span id="About-subtitle-goals" className="About-subtitle subtitle">Sustainable Development Goals</span>
                    <div id="About-icon-container">
                        <img className="About-icon" src={this.props.path + "/Images/About/12.png"} />
                        <img className="About-icon" src={this.props.path + "/Images/About/13.png"} />
                        <img className="About-icon" src={this.props.path + "/Images/About/15.png"} />
                    </div>
                </div>
            </div>
        )
    }
}

export default About; 