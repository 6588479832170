import React, { Component } from 'react';
import './css/TopNavigationBar.css';

class TopNavigationBar extends Component {

    render_fun(data) {
        return (
            data.map((datum, key) => {
                if (datum.display_name === "Home") {
                    return null;
                }

                return (
                    <span
                        key={key}
                        style={{ order: key }}
                        className={"large-menu-item" + (this.props.current_page === datum.fetch_name ? " current_page" : "")}
                        onClick={() => this.props.fetchPage(datum.fetch_name, this.props.view)}
                    >
                        {datum.display_name}
                    </span>
                )
            })
        )
    }

    render() {
        console.log()
        return (
            <div id="top-navigation-bar">

                <img
                    id="menu-button"
                    src={this.props.path + "/images/menu.png"}
                    onClick={() => this.props.menuSwitch(this.props.view)}
                />
                <img
                    id={this.props.data.logo.image_id}
                    src={this.props.path + "/images/logo/" + this.props.data.logo.src}
                    alt={this.props.data.logo.alt}
                    onClick={() => this.props.fetchPage("Home", this.props.view)}
                />
                <span
                    className={"large-menu-item" + (this.props.current_page === "News" ? " current_page" : "")}
                    onClick={() => this.props.fetchPage("News", this.props.view)}
                >
                    News
                </span>
                {this.render_fun(this.props.data.menu_item)}
            </div>
        )
    }
}

export default TopNavigationBar; 