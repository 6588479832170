// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#BSF-container{
    width:auto; 
    height: 100%; 
    min-height: 60vh; 
    margin: 20px; 
    display: flex; 
    flex-direction: column; 
    line-height: 2; 
    align-items: center;
}

#BSF-title{
    position: relative; 
    margin: 30px;
    font-weight: bold; 
}

.BSF-item-container{
    display:flex; 
    flex-direction: column;
    align-items: center;
}

.BSF-column{
    display: flex; 
    height: auto; 
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content; 
    margin-left: 20px; 
}

#BSF-main{
    width: 70%; 
    height: auto; 
    align-self: center;
}

.BSF-row{
    display: flex; 
    justify-content: space-evenly;
    width: 100%; 
}
@media (min-width:1000px){
    #BSF-title{
        align-self: center;
        font-size: 40px;
    }

    #BSF-container{
        font-size: 25px;
        line-height: 1.5;
        justify-content: center;
    }
    .BSF-row{
        max-height:  70vh;
    }

    #BSF-main{
        align-self: unset;
    }
}
`, "",{"version":3,"sources":["webpack://./src/View/css/BSF.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,WAAW;AACf;AACA;IACI;QACI,kBAAkB;QAClB,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,gBAAgB;QAChB,uBAAuB;IAC3B;IACA;QACI,iBAAiB;IACrB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["#BSF-container{\n    width:auto; \n    height: 100%; \n    min-height: 60vh; \n    margin: 20px; \n    display: flex; \n    flex-direction: column; \n    line-height: 2; \n    align-items: center;\n}\n\n#BSF-title{\n    position: relative; \n    margin: 30px;\n    font-weight: bold; \n}\n\n.BSF-item-container{\n    display:flex; \n    flex-direction: column;\n    align-items: center;\n}\n\n.BSF-column{\n    display: flex; \n    height: auto; \n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: center;\n    width: fit-content; \n    margin-left: 20px; \n}\n\n#BSF-main{\n    width: 70%; \n    height: auto; \n    align-self: center;\n}\n\n.BSF-row{\n    display: flex; \n    justify-content: space-evenly;\n    width: 100%; \n}\n@media (min-width:1000px){\n    #BSF-title{\n        align-self: center;\n        font-size: 40px;\n    }\n\n    #BSF-container{\n        font-size: 25px;\n        line-height: 1.5;\n        justify-content: center;\n    }\n    .BSF-row{\n        max-height:  70vh;\n    }\n\n    #BSF-main{\n        align-self: unset;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
