import React, {Component} from 'react';
import './css/Common.css';

class NotFound extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div> 
                Page Not Found. 
            </div>
        )
    }
}

export default NotFound; 