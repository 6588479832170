import React, { Component } from 'react';
import './css/Contact.css';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.name_input = React.createRef();
        this.email_input = React.createRef();
        this.message_input = React.createRef();
        this.option_selected = React.createRef();
    }

    render_list(data) {
        return data.map((datum, key) => {
            return (<option key={key} className="contact-option" value={datum.option}>{datum.option}</option>)
        })
    }

    async submitForm(e) {
        e.preventDefault();
        var client_name = this.name_input.current.value;
        var email = this.email_input.current.value;
        var message = this.message_input.current.value;
        var option = this.option_selected.current.value;
        console.log(message === "");
        if(option === ""){
            window.alert("Please select from the options")
            return; 
        }
        if (client_name === "") {
            window.alert("Please enter your name");
            return;
        }
        if (!email.includes("@")) {
            window.alert("Please enter your email");
            return;
        }
        if (message === "") {
            window.alert("Please enter your message");
            return;
        }
        const page = 'Contact';
        var data = {
            option: option,
            client_name: client_name,
            email: email,
            message: message,
        }

        await this.props.command(page, 'POST', data, this.props.view);
        this.name_input.current.value = null;
        this.email_input.current.value = null;
        this.message_input.current.value = null;
        this.option_selected.current.value = null;

    }

    render() {
        return (
            <div id="contact-content" >
                <div id="contact-title" > Contact Us </div>
                <select id="contact-option-list" ref={this.option_selected} >
                <option className="contact-option" value="">-Please Select-</option>
                    {this.render_list(this.props.data.contact_option)}
                </select>
                <div id="form-content">
                    <div className="form-item">
                        <span className="contact-subtitle">
                            Name
                        </span>
                        <input type="text" ref={this.name_input} />
                    </div>
                    <div className="form-item">
                        <span className="contact-subtitle">
                            Email
                        </span>
                        <input type="text" ref={this.email_input} />
                    </div>
                    <div className="form-item">
                        <span id="contact-content-input" className="contact-subtitle">
                            Content
                        </span>
                        <textarea ref={this.message_input} />
                    </div>

                    <span className="submit-btn" onClick={(e) => { this.submitForm(e) }}>Submit</span>
                </div>
            </div>
        )
    }
}

export default Contact; 