// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-icon {
    cursor: pointer;
    width: auto;
    height: 15vh; 
    margin-right: 30px;
    aspect-ratio: 1/1;
    border-radius: 30px; 
    align-self: center;
}

.product {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
}

#product-container {
    display:flex; 
    flex-direction: column; 
    justify-content: flex-start; 
    width:80%; 
}

.product-description{
    display: flex; 
    flex-direction: column;
    align-items: baseline;
    justify-content: space-evenly;
    margin: 0px; 
    text-align: left;
}

h4{
    margin: 0px;
}

@media (min-width: 1000px){
    #product-container {
        display:grid; 
        width:100%; 
        grid-template-columns: 1fr 1fr; 
        gap: 40px;
    } 
    .product-icon {
        cursor: pointer;
        width: auto;
        height: 30vh; 
        max-height: 200px;  
        margin-right: 30px;
        aspect-ratio: 1/1;
        border-radius: 30px; 
    }
}
`, "",{"version":3,"sources":["webpack://./src/View/css/Highlights.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,2BAA2B;IAC3B,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,6BAA6B;IAC7B,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,YAAY;QACZ,UAAU;QACV,8BAA8B;QAC9B,SAAS;IACb;IACA;QACI,eAAe;QACf,WAAW;QACX,YAAY;QACZ,iBAAiB;QACjB,kBAAkB;QAClB,iBAAiB;QACjB,mBAAmB;IACvB;AACJ","sourcesContent":[".product-icon {\n    cursor: pointer;\n    width: auto;\n    height: 15vh; \n    margin-right: 30px;\n    aspect-ratio: 1/1;\n    border-radius: 30px; \n    align-self: center;\n}\n\n.product {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 40px;\n}\n\n#product-container {\n    display:flex; \n    flex-direction: column; \n    justify-content: flex-start; \n    width:80%; \n}\n\n.product-description{\n    display: flex; \n    flex-direction: column;\n    align-items: baseline;\n    justify-content: space-evenly;\n    margin: 0px; \n    text-align: left;\n}\n\nh4{\n    margin: 0px;\n}\n\n@media (min-width: 1000px){\n    #product-container {\n        display:grid; \n        width:100%; \n        grid-template-columns: 1fr 1fr; \n        gap: 40px;\n    } \n    .product-icon {\n        cursor: pointer;\n        width: auto;\n        height: 30vh; \n        max-height: 200px;  \n        margin-right: 30px;\n        aspect-ratio: 1/1;\n        border-radius: 30px; \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
