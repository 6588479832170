import React, { Component } from 'react';
import './css/SlideShow.css';

class SlideShow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			position: "0vw",
		}
	}

	pic_slider() {
		var pics = this.props.data;
		var num_pics = pics.length;

		var output = pics.map((pic, key) => {
			return (
				<img
					id={pic.image_id}
					className="testing-pic"
					key={key}
					src={this.props.path + "/Images/highlights/" + pic.src}
					alt={pic.alt}
				/>
			)
		});

		var buttons = pics.map((pic, key) => {
			return (
				<div
					className="testing-button"
					key={key}
					onClick={() => this.setState({
						position: (key * -75) + "vw"
					})} />
			)
		})

		console.log(this.state.position); 
		return (
			<div className="testing-container">
				<div className="testing-container2">
					<div
						className="testing-image-container"
						style={{
							"position": "relative",
							"width": `calc( 75vw * ${num_pics})`,
							"height": "fit-content",
							"transition": "left 2s",
							"left": this.state.position,
						}}
					>
						{output}
					</div>

				</div>
				<div className="testing-button-container">
					{buttons}
				</div>
				{
					this.state.position.slice(0,-2) < 0 ? 
					<div className="testing-arrow" id = "testing-left-arrow" onClick={() => this.setState({ position: parseInt(this.state.position.slice(0, -2)) + 75 + "vw" })}>
						{String.fromCharCode(8249)}
					</div> : null
				}
				{
					this.state.position.slice(0,-2) > (num_pics-1) * -75 ? 
					<div className="testing-arrow" id = "testing-right-arrow" onClick={() => this.setState({ position: this.state.position.slice(0, -2) - 75 + "vw" })}>
						{String.fromCharCode(8250)}
					</div> : null
				}
			</div>
		);
	}

	render() {
		var pic = this.props.data[this.state.index];
		return (
			<div>
				{this.pic_slider()}
			</div>
		)
	}
}

export default SlideShow; 