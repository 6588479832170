// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Lifecycle-image{
    width: auto; 
    max-width: 800px;
    height: auto; 
    aspect-ratio: 1.3;
}

#Lifecycle-container{
    display: flex;
    justify-content: center;
    margin-top: 100px; 
    min-height: 60vh;
}

@media (min-width: 1000px){
    #Lifecycle-image{
        width: 80%; 
        height: auto; 
        margin-bottom: 20px; 
        margin: 50px 10px 50px 10px;
    }

    #Lifecycle-container{
        display: flex;
        justify-content: center;
        margin-top: 100px; 
        min-height: 60vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/View/css/Lifecycle.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,UAAU;QACV,YAAY;QACZ,mBAAmB;QACnB,2BAA2B;IAC/B;;IAEA;QACI,aAAa;QACb,uBAAuB;QACvB,iBAAiB;QACjB,gBAAgB;IACpB;AACJ","sourcesContent":["#Lifecycle-image{\n    width: auto; \n    max-width: 800px;\n    height: auto; \n    aspect-ratio: 1.3;\n}\n\n#Lifecycle-container{\n    display: flex;\n    justify-content: center;\n    margin-top: 100px; \n    min-height: 60vh;\n}\n\n@media (min-width: 1000px){\n    #Lifecycle-image{\n        width: 80%; \n        height: auto; \n        margin-bottom: 20px; \n        margin: 50px 10px 50px 10px;\n    }\n\n    #Lifecycle-container{\n        display: flex;\n        justify-content: center;\n        margin-top: 100px; \n        min-height: 60vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
