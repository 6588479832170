/* eslint-disable react/no-direct-mutation-state */

import React, { Component } from 'react';
import Model from "./Model.js"; 

class ViewModel extends Component { 
    constructor(props) {
		super(props);
		this.state = {
			loading: false, 
			current_page: "Home",
			data: "", 
			confirm: false, 
			menu: "off", 
		}
		this.fetchPage = this.fetchPage.bind(this); 
		this.switchPage = this.switchPage.bind(this);
		this.menuSwitch = this.menuSwitch.bind(this); 
		this.command = this.command.bind(this); 
		this.fileCommand = this.fileCommand.bind(this); 
		this.closeConfirmation = this.closeConfirmation.bind(this); 
	}

	closeConfirmation(view){
		this.state.confirm = false; 
		view.forceUpdate(); 
	}

    async fetchPage(page, view){
		if (page != null){
			this.state.current_page = page; 
		}
        var data = await Model.fetchPage(this.state.current_page); 
         
        this.state.data = data; 
		this.state.loading = true;
		window.scrollTo(0, 0);
		view.forceUpdate(); 
    }

	switchPage(page, view){
		this.state.current_page = page; 
		window.scrollTo(0, 0);
		view.forceUpdate(); 
	}

	async fileCommand(page, data, view){
		var response = await Model.fileCommand(page, data); 
		if (response.confirm == true){
			this.state.confirm = true; 
		}
		this.state.current_page = page; 
		if (response != null){
			this.state.data = response; 
		}
		else{
			this.state.data = ""; 
		}
		window.scrollTo(0, 0);
		view.forceUpdate(); 
	}

	async command(page, method, data, view){
		var response = await Model.command(page, method, data); 
		if (response.confirm == true){
			this.state.confirm = true; 
		}
		this.state.current_page = page; 
		if (response != null){
			this.state.data = response; 
		}
		else{
			this.state.data = ""; 
		}
		window.scrollTo(0, 0);
		view.forceUpdate(); 
	}

	menuSwitch(view){
		this.state.menu === "off" ? this.state.menu = "on" : this.state.menu ="off"; 
		view.forceUpdate(); 
	}

}

ViewModel = new ViewModel(); 
export default ViewModel; 