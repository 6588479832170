// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tech-container{
    display:flex; 
    flex-direction: column;
    align-items: center;
    margin: 50px; 
}

.tech-img{
    height:auto; 
    width:70%; 
    margin: 25px;
}

.tech-subtitle{
    margin: 0px; 
}

.tech-title{
    align-self: baseline;
    font-weight:bold; 
}

.tech-description, .tech-description2, .tech-info{
    text-align: center;
    width: 90%; 
    max-width: 900px; 
    margin: 20px;
}

.tech-description, .tech-description2 {
    color: rgb(54, 49, 49); 
}

.tech-description2{
    text-align: start;
    
}`, "",{"version":3,"sources":["webpack://./src/View/css/Technology.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;;AAErB","sourcesContent":[".tech-container{\n    display:flex; \n    flex-direction: column;\n    align-items: center;\n    margin: 50px; \n}\n\n.tech-img{\n    height:auto; \n    width:70%; \n    margin: 25px;\n}\n\n.tech-subtitle{\n    margin: 0px; \n}\n\n.tech-title{\n    align-self: baseline;\n    font-weight:bold; \n}\n\n.tech-description, .tech-description2, .tech-info{\n    text-align: center;\n    width: 90%; \n    max-width: 900px; \n    margin: 20px;\n}\n\n.tech-description, .tech-description2 {\n    color: rgb(54, 49, 49); \n}\n\n.tech-description2{\n    text-align: start;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
