import React, { Component } from 'react';
import './css/GuidedTour.css';

class GuidedTour extends Component {

    render() {
        return (
            <div id="GuidedTour-container">
                <div id="GuidedTour-title" className = "title"> Guided Tour</div>
                <div className="GuidedTour-flex">
                    <img
                        src={this.props.path + "/Images/GuidedTour/guide.png"}
                        alt="Tour guide"
                        className="GuidedTour-image"
                    />
                    <div className="GuidedTour-description1">
                        The BFS Farm guided tour is an educational and engaging activity that introduces participants to the world of black soldier flies. The tour includes an in-depth explanation of the life cycle and growth patterns of black soldier flies, as well as a visit to the BFS farm to observe how they are raised and harvested. The tour also includes a Q&A session with expert guides and a hands-on activity where participants can learn how to make environmentally friendly enzymes using black soldier fly larvae. The goal of the tour is to increase awareness of the benefits of black soldier flies and their potential as a sustainable resource.
                    </div>
                </div>
                <div className="GuidedTour-flex1">
                    <img
                        id="GuidedTour-GJ"
                        className="GuidedTour-image"
                        src={this.props.path + "/Images/GuidedTour/GJ.png"}
                        alt="Good Job!"
                    />
                    <div className="GuidedTour-description2">
                        Learn about producing environmentally friendly enzymes for waste management.
                    </div>
                </div>

                <img id="GuidedTour-booking" src={this.props.path + "/Images/Workshop/booking.png"} onClick={() => { this.props.fetchPage("Contact", this.props.view) }} />
            </div>
        )
    }
}

export default GuidedTour; 