import React, { Component } from 'react';
import './css/Workshop.css';

class Workshop extends Component {

    render() {
        return (
            <div id="Workshop-container" className= "grey">
                <div id = "Workshop-title" className= "title">Workshop</div>
                <div className = "Workshop-flex">
                    <img
                        src={this.props.path + "/Images/Workshop/BSF.png"}
                        alt="Worm with shield"
                        className= "Workshop-icon"
                    />
                    <div className = "Workshop-text">
                        <span id="Workshop-W">W</span>elcome to the BSF Workshop! <br />
                        Our workshop is a fun and interactive way to learn about
                        black soldier flies and their role in sustainable waste
                        management and animal feed production. You don't need any
                        prior knowledge or experience to participate. <br />
                        Our workshop is suitable for anyone interested in
                        sustainable waste management, environmental conservation.
                        Our team will make sure that you have a fun and informative
                        learning experience. <br />
                        We look forward to seeing you at our workshop!
                    </div>
                </div>
                
                <div className = "Workshop-image-container">
                    <img
                        src={this.props.path + "/Images/Workshop/comics1.png"}
                        alt="comic1"
                        className="Workshop-image"
                    />
                    <img
                        src={this.props.path + "/Images/Workshop/comics2.png"}
                        alt="comic2"
                        className="Workshop-image"
                    />
                </div>
                <div className="Workshop-content">
                    “As a black soldier fly guardian of the environment,
                    it is my duty to clean up after humans and keep the world tidy.”
                </div>
                <img
                    src={this.props.path + "/Images/Workshop/spyglass.png"}
                    alt="Book Now!"
                    id="Workshop-spyglass"
                />
                <div className="Workshop-content2">
                    Here's Here's what you can expect:
                    <ul>
                        <li>
                            Observe live black soldier flies and learn about their life cycle
                            and characteristics.
                        </li>
                        <li>
                            Discover the importance of black soldier flies in environmental
                            conservation and how they can efficiently convert organic waste
                            into valuable resources.
                        </li>
                        <li>
                            Get guidance on setting up a sustainable waste management
                            systemusing black soldier flies in various settings such
                            as home, farm, or community.
                        </li>
                    </ul>
                </div>
                <img
                    src={this.props.path + "/Images/Workshop/booking.png"}
                    alt="Book Now!"
                    id="Workshop-booking"
                    onClick={() => this.props.fetchPage("Contact", this.props.view) }
                />
            </div>
        )
    }
}

export default Workshop; 